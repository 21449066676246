//Me permite ver los resultados luego de RESOLVER-SRI
//recibe un JSON y ademas el tipo de documento [FACTURA,GUIA,NOTA CREDITO,RETENCION...] y los 17 digitos
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//variables normales
let valueDC=null //Aqui hago una copia de los valores del context 

const MostrarResultadosResolver=(props)=>{
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const mostrarDatos=()=>{    
  if (props.resolverJson.Xml==true) {
    document.getElementById("txtXml").value="Listo"
    //cambio de color de fondo ROJO al VERDE
    document.getElementById("txtXml").style.background='green'
  }
  if (props.resolverJson.Firmado==true) {
    document.getElementById("txtFirmado").value="Listo"
    document.getElementById("txtFirmado").style.background='green'
  }
  if (props.resolverJson.EnviadoAlSri==true) {    
    document.getElementById("txtEnviadoAlSri").value="Listo"
    document.getElementById("txtEnviadoAlSri").style.background='green'
  }
  if (props.resolverJson.Autorizado==true) {
    document.getElementById("txtAutorizado").value="Si"
    document.getElementById("txtAutorizado").style.background='green'
  }  
  if (props.resolverJson.Autorizado==false) {
    document.getElementById("txtAutorizado").value="No"
  }    
  if (props.resolverJson.Pdf==true) {
    document.getElementById("txtPdf").value="Listo"
    document.getElementById("txtPdf").style.background='green'
  }    
  if (props.resolverJson.EnviadoPorCorreo==true) {
    document.getElementById("txtCorreo").value="Listo"
    document.getElementById("txtCorreo").style.background='green'
  }      
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  mostrarDatos()

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //por ahora nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{
return (               
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

    {/* Titulo + Boton Cerrar*/}
    <Row style={{margin:'0',}}>
      <Col xs="9" >
        <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"RESOLVER (resultado)"}</Label>
      </Col>
      <Col xs="3" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ props.cerrarModal()  }} >
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
    </Row> 

    {/* Empiezo a mostrar fontSize:'16px' */}
    <div style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink', }}>
      <Row style={{marginBottom:"4%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="12">
          <Input disabled name="txtDocumentoNumero" id="txtDocumentoNumero" value={props.documentoTipoYnum} style={{paddingLeft:'3px', textAlign:'center',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="7" style={{textAlign:'right'}}>
          <Label>Xml --&gt;</Label>
        </Col>
        <Col xs="4">
          <Input disabled name="txtXml" id="txtXml" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'red' }}/>            
        </Col>    
        <Col xs="1"></Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="7" style={{textAlign:'right'}}>
          <Label>Firmado --&gt;</Label>
        </Col>
        <Col xs="4">
          <Input disabled name="txtFirmado" id="txtFirmado" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'red' }}/>
        </Col>    
        <Col xs="1"></Col>
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="7" style={{textAlign:'right'}}>
          <Label>Enviado al sri --&gt;</Label>
        </Col>
        <Col xs="4">
          <Input disabled name="txtEnviadoAlSri" id="txtEnviadoAlSri" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'red' }}/>            
        </Col>    
        <Col xs="1"></Col>
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="7" style={{textAlign:'right'}}>
          <Label>Autorizado --&gt;</Label>
        </Col>
        <Col xs="4">
        <Input disabled name="txtAutorizado" id="txtAutorizado" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'red' }}/>            
        </Col>    
        <Col xs="1"></Col>
      </Row>  
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="7" style={{textAlign:'right'}}>
          <Label>Pdf --&gt;</Label>
        </Col>
        <Col xs="4">
          <Input disabled name="txtPdf" id="txtPdf" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'red' }}/>            
        </Col>    
        <Col xs="1"></Col>
      </Row>  
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="7" style={{textAlign:'right'}}>
          <Label>Email enviado -&gt;</Label>
        </Col>
        <Col xs="4">
          <Input disabled name="txtCorreo" id="txtCorreo" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'red' }}/>            
        </Col>    
        <Col xs="1"></Col>
      </Row>      
    </div>  
  </div> // divMaster)
) //del return interno
}  

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del MostrarResultadosResolver

export default MostrarResultadosResolver
