//Me permite ver los campos basicos de la firma electronica 
//alert(JSON.stringify(jsonNegra,null,2))
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context

const MostrarDatosBasicosFirmaElectronica=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const mostrarDatos=()=>{     
  document.getElementById("txtContribuy").value=props.pepeJson.Contribuyente
  document.getElementById("txtCedula").value=props.pepeJson.CedulaContribuyente
  document.getElementById("txtRuc").value=props.pepeJson.RucContribuyente
  document.getElementById("txtCertificante").value=props.pepeJson.Certificante
  let desde=props.pepeJson.DesdeDia + "/" + props.pepeJson.DesdeMes + "/" + props.pepeJson.DesdeAno
  let hasta=props.pepeJson.HastaDia + "/" + props.pepeJson.HastaMes + "/" + props.pepeJson.HastaAno
  document.getElementById("txtDesde").value=desde
  document.getElementById("txtHasta").value=hasta  
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{ 
  mostrarDatos()
  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //por ahora, nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{  
return (               
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
    {/* Titulo + Boton Cerrar */}
    <Row style={{margin:'0',}}>
      <Col xs="10" >
        <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"FIRMA ELECTRONICA"}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ props.cerrarModal()  }} >
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
    </Row> 

    {/* Muestro los datos basicos */}
    <div id="divDatos" name="divDatos" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink', }}>

    <Row style={{marginBottom:"4%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="12">
          <Input disabled name="txtContribuy" id="txtContribuy" style={{paddingLeft:'3px',textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>
    </Row>  
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Cédula</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtCedula" id="txtCedula" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
    </Row>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Ruc</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtRuc" id="txtRuc" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Desde</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtDesde" id="txtDesde" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Hasta</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtHasta" id="txtHasta" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Certificante</Label>
        </Col>
        <Col xs="8">
          <textarea disabled name="txtCertificante" id="txtCertificante" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'90px', }}/>            
        </Col>    
      </Row>  

    </div>  
  </div> // divMaster
)  
}  

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} // de MostrarDatosBasicosFirmaElectronica

export default MostrarDatosBasicosFirmaElectronica
