import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//para encriptar
import bcrypt from 'bcryptjs'
//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
 
const EncriptarTextoEnJS=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const encriptarYmostrar=()=>{
  let textoPlano=(document.getElementById("txtClave").value).trim()
  //textoPlano=textoPlano.toLowerCase() 

  //hago la encriptacion
  let miHash = bcrypt.hashSync(textoPlano, 10);
  alert(miHash)            

  //let miStatus=bcrypt.compareSync("8282333", miHash)
  //alert(miStatus)  
}
 
//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{ 
  document.getElementById("txtClave").focus()  

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //por ahora, nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{
return (
<form>
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

  {/* Boton Cerrar*/}
  <Row style={{marginTop:"2px"}}>
    <Col xs="10" >
      <Label style={{marginLeft:'2%',fontWeight: 'bold'}}>{'Indíque un texto para encriptar en JS'}</Label>
    </Col>
    <Col xs="2">
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ props.cerrarModal()  }} >
    <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
    </Col>
  </Row> 
  <div style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink'}}>
    <Row >
      <Col xs="7" >
        <Input style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px' }} name="txtClave" id="txtClave"      
        onKeyDown = {(e) => { 
          if ( e.key =="Enter"  ){ 
            //e.preventDefault()   //obligatorio ponerlo
            encriptarYmostrar()                                 
          }
        }}/>      
      </Col>

      <Col xs="5" style={{textAlign:'center',}}>    

      <Button style={{width:'120px', height:'120px',fontSize:'60px',}} id="btnEnter" name="btnEnter" color="success" 
        onClick= {()=> {
          encriptarYmostrar() 
        }}>
        <FontAwesomeIcon color="purple" icon={faCheck} /> 
      </Button>

      </Col>         
    </Row>  
    </div>  
  </div> {/* //div master */}
</form>               
)}

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} // de EncriptarTextoEnJS

export default EncriptarTextoEnJS
