//Me permite ver el contenido del context
//alert(JSON.stringify(jsonNegra,null,2))
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
 
const VerContext=(props)=> {
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{ 
  document.getElementById("txtContext").value=JSON.stringify(valueDC,null,2)

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //por ahora, nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{  
return (               
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

  {/* Titulo + Boton Cerrar */}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"CONTEXT"}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ props.cerrarModal()  }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 

  {/* Muestro los datos basicos */}
  <div id="divDatos" name="divDatos" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink', }}>
    <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
      <Col >
        <textarea name="txtContext" id="txtContext" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'70vh', }}/>            
      </Col>    
    </Row>  
  </div>  
  </div> // divMaster
)  
}  

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} // de VerContext

export default VerContext
