//FALTA:falta calcular el abono del recibo. 
//me muestra un modal con una lista de todos los recibos con que se ha pagado una Factura/Nota/Plan...
//OJO: solo para las facturas a credito se le puede activar el boton ELIMINAR RECIBO.
//Solo para las facturas, se le pone la etiqueta FACTURA/CREDITO o FACTURA/CONTADO. (la idea es que el usuario esté claro de que tipo de factura se muestran los recibos)
import React, {useState,useEffect} from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import {Modal, Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGlasses, faAngleDoubleDown, faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
import buscarPhpPath,{sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal,tildarCampoEnviadoPorCorreoNoSriSinSwal,eliminarPagoDeCxCconSwal,hayInternet,ejecutarFetchGenericoConSwal } from './lib_basica'
//componentes necesarios
import VerPdfOk from './VerPdfOk'
import FormularioCorreo from './FormularioCorreo'
import PedirClaveEspecial from './PedirClaveEspecial'
//variables normales
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //obtengo una Fila (row) clonada, completa para multiples usos

const miEstilacho={
	table: {
		style: {
			minHeight: '30vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'Recibos de caja'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'dimgray',
		},
	},    
  headCells: {
    style: {
      background: 'dimgray', //ok hotpink
      color: 'lavender',  //ok lavender
      fontSize:'16px',   //ok
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },  
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',            //thin
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: (row) => (row.Recibo != filaClonada?.Recibo ),
    style: row => ({ 
      backgroundColor:'white',
      color: 'dimgray',
     }),    
  },  
  //fila NO seleccionada  
  {
    when: (row) => (row.Recibo == filaClonada?.Recibo ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black', 
      //backgroundColor: 'red'
    }),
  },
]

const RecibosIngresoDeUnDocumento=(props)=>{   
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros Osea al inico es lo que está liago a la tabela
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela    
  //modales
  const [modalPedirClaveEspecial,set_modalPedirClaveEspecial] =useState(false) //para pedir la clave especial
  const [modalEnviarCorreo,set_modalEnviarCorreo] =useState(false) //para enviar el correo
  const [modalVerRecibo,set_modalVerRecibo] =useState(false) //para ver el pdf del recibo

const miEstructuraDataTable=[  
  {  
    name:'RECIBO',
    selector:row => row.Recibo, //Tal como viene en el Json desde las Base de datos (17caracteres)
    sortable:false,
    center:true,
    //compact:true,
    grow:1,
  },  
  {
    name:'MONTO',
    selector:row => row.MontoDelRecibo, 
    sortable:false,
    right:true,
    grow:0.4,
  }, 
{
  //ACCIONES
  name:'',
  sortable:false,
  center:true,
  //grow:0.4,  
  cell: (row) => 
  <div style={{ display:'flex',}}>            
    {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
    {/* tambien da error al poner raised. se debe poner raised="true"  */}
    {/* o se puede poner raised= {miVariable.toString() }  */}

  {/* boton eliminar, Solo aplica cuando el documento sea tipo CREDITO */}
  <Button id="btnEliminar" name="btnEliminar" style={{marginRight:'1px',display: props.condicion=='CONTADO' ? 'none' : 'block' }} raised = "true" primary='false' color="danger" 
    onClick={ () => {
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_modalPedirClaveEspecial(true)
    }}>
    <span style ={{fontSize:"20px"}}><FontAwesomeIcon color="pink" icon={faTrashAlt} /></span>
  </Button>

  {/* boton ver recibo */}
  <Button id="btnVer" name="btnVer" style={{ marginRight:'1px' }} raised = "true" primary='false' color="primary" 
    onClick={ async() => {
      set_rowRegistroState(row)
      rowRegistroClon=row
      //dataApi, puede recibir null o el mismo numero de Recibo enviado. Nunca viene negativo
      let dataApi=await generarPDFdelRecibo() //Si todo sale bien, entonces llamdo al MODAL para ver el PDF      
      if (dataApi==null) return
      set_modalVerRecibo(true)
    }}>
    <span style ={{fontSize:"20px"}}><FontAwesomeIcon color="pink" icon={faGlasses} /></span>
  </Button>

  {/* boton Enviar x correo */}
  <Button id="btnEnviarEmail" name="btnEnviarEmail" style={{ marginRight:'0px' }} raised = "true" primary='false' color="primary" 
    onClick={ async() => {       
      set_rowRegistroState(row)
      rowRegistroClon=row
      //dataApi, puede recibir null o el mismo numero de Recibo enviado. Nunca viene negativo
      let dataApi=await generarPDFdelRecibo() //Si todo sale bien, entonces llamo al MODAL para escribir asunto y cuerpo del mensaje
      if (dataApi==null) return
      if (props.clienteEmail.trim()==''){
        await mostrarSwalBotonAceptar("warning","ATENCION","El cliente no tiene correo")                          
        return
      }
      set_modalEnviarCorreo(true)
    }}>
      <span style ={{ fontSize:"20px"}}><FontAwesomeIcon color="pink" icon={faEnvelope} /></span>        
  </Button>
 </div>,
},
  {
    name:'FECHA',
    selector:row => row.FechaReciboFormat, 
    sortable:false,
    center:true,
    grow:0.7,   
  },   
  {  
    name:'ExC',
    selector:row => row.EnviadoSiNo, 
    sortable:false,
    center:true,
    grow:0.3,
    hide:'md', //aparentemente oculta de md hacia atras
  },
]

const generarPDFdelRecibo=async()=>{
  //llamo a la funcion para generar.
  //Puedo recibir null, o positivo
  let data=new FormData()
  data.append('miSol','reciboingreso_gestionarGenerarPDF')
  data.append('miFormato','A4v')
  data.append('miEstab',rowRegistroClon.Estab)
  data.append('miPunto',rowRegistroClon.Punto)
  data.append('miNumRecibo',rowRegistroClon.NumRecibo)

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  return dataApi
}

const sumarizarAbonosYsalir=()=>{
  //Hago una sumatoria de los abonos (la idea es actualizar la tabela del llamador ya que alomejor aqui se eliminaron recibos)
  //OJO: en un futuro, se debe mejorar esta parte (al haber pagado varias facturas en un mismo recibo, entonces la sumatoria estaria incorrecta)
  //el campo de referencia es MontoDelRecibo
  let sumaDeAbonos=0

  if (registrosFullState) //al tener datos
    registrosFullState.forEach( (miFila)=> {
      sumaDeAbonos += parseFloat(miFila.MontoDelRecibo)
    })
    
  let todoOKsn="s"
  props.cerrarModal(todoOKsn,sumaDeAbonos)
}

const refrescarData=async()=>{ 
  let data=new FormData()
  data.append('miSol','reciboingreso_buscarRecibosGeneradosPorUnDocumento')
  data.append('EstabDG',props.estabDG)
  data.append('PuntoDG',props.puntoDG)
  data.append('NumDG',props.numDG)
  data.append('PrefijoDG',props.prefijoDG)  

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(dataApi)   
}
  
const ponerCabecera=()=>{
  //titulo
  document.getElementById("miTitulo").innerText="Recibos de caja de " + props.clienteNombre + (props.clienteEmail.length>0 ?  (" / " + props.clienteEmail) : "" )
  //Tipo de coumento [FACTURA, NOTA DE ENTREGA, PLAN...]
  let miTipoDoc=null
  switch (props.prefijoDG) {
    case 'FA':
      miTipoDoc='FACTURA / ' + props.condicion
      break;
    case 'NE':
      miTipoDoc='NOTA'
      break
    case 'PL':
      miTipoDoc='PLAN'
      break
  }
  document.getElementById("txtTipoDoc").value= miTipoDoc
  //Numero
  document.getElementById("txtNumDoc").value= props.doc17DG
  //Fecha
  document.getElementById("txtFecha").value= props.fechaDG
  //Monto total del documento generador
  document.getElementById("txtMonto").value= props.montoDG
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  ponerCabecera()
  refrescarData() //al haber algun error, se regresa al llamador automaticamente

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    rowRegistroClon=null 
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }  
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al llamador
useEffect(()=>{  
  if (registrosFullState==null){    
    props.cerrarModal() 
  }
  },[registrosFullState]
)

const ParteGrid=()=>{
return(
  <div id="divTabela" name="divTabela" style={{borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'gray', width:'98%', marginLeft:'1%', marginRight:'1%', marginBottom:'20px',}} > 
    <DataTable
      //************ DATA TABLA PROPERTIES (basic) ***********/
      title='Recibos de caja'
      columns={miEstructuraDataTable}
      data={registrosFullState ?? []}
      conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)}  
      keyField ='Recibo' /* Se puede poner 'id' (valor por omision), 'Fila' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
      onRowClicked={(row) => { 
          //setState({filaState:row.Recibo.trim(),estabRecibo:row.Estab.trim(),puntoRecibo:row.Punto.trim(),numRecibo:row.NumRecibo.trim(),})  
          set_rowRegistroState(row)
          rowRegistroClon=row          
        }}
      noDataComponent={null}
      //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
      customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    <Label id="labelExC" name="labelExC" style={{marginTop:'2%', marginLeft:'2%',}}>ExC: Enviado por Correo</Label>    
  </div>
  )  
}

const colocarSIenExC=()=>{
  //lo unico que hace es modificar el JSON del estado poner "Si" en la columna ExC (enviado x correo) y tildar tambien en la BDD
  let datosFullCopia=registrosFullState.map(item=>{
    if (item.Recibo==rowRegistroClon.Recibo){
      item.EnviadoSiNo="Si"
    }   
    return item
  })
  set_registrosFullState(datosFullCopia)

  //tildo en la base de datos, pero no me interesa esperar la respuesta. 
  //El valor 1 que le envio signfica que quiero guardar TRUE en la columna Enviado de la tabla Recibos
  let tildadoCorrectamenteSN=tildarCampoEnviadoPorCorreoNoSriSinSwal("REC",1,rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumRecibo,valueDC)
  return tildadoCorrectamenteSN //por ahora no es necesario hacer nada con esta variable
}

const gestionarEliminarRecibo=async()=>{    
  //este pedazo de validaciones, queda para el futuro
  //se deberia validar si se ha pagado en grupo. Para dar una advertencia

  /*
  if (state.factura17==''){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Factura")          
    return
  }
  //reviso si ya esta anulada previamente en el grid (es decir si ya estaba tachada)
  if (rowFactura.Anulado==1){
    mostrarSwalBotonAceptar("warning","ATENCION","Esta Factura ya estaba anulada")          
    return
  }
*/

  let dataApi=await eliminarReciboEnBDD() //puede ser null, negativo o el mismo numero enviado
  if (dataApi==null) return

  if (dataApi==-110){
    await mostrarSwalBotonAceptar("error","ATENCION","A las Facturas tipo Contado no es posible eliminar los recibos")              
    return
  }

  //todo OK, es lo ideal
  //elimino de la tabela, el recibo eliminado
  let copiaFull=registrosFullState.filter(item=> item.Recibo!=rowRegistroClon.Recibo )
  set_registrosFullState(copiaFull)        
  await mostrarSwalBotonAceptar("success","OK","Recibo eliminado correctamente")     
}

const eliminarReciboEnBDD=async()=>{
  let data=new FormData()
  data.append('miSol','cxc_eliminarRecibo')
  data.append('miEstabDG', rowRegistroClon.Estab)  
  data.append('miPuntoDG', rowRegistroClon.Punto)
  data.append('miNumDG', rowRegistroClon.NumRecibo)

  //recibo desde mi Api, null, negativo o positivo
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  return dataApi
}

const ComponentePrincipalBody=()=>{
return (  
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* encabezado y boton X */}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label id="miTitulo" name="miTitulo" style={{marginTop:'5px',fontWeight: 'bold'}}>Recibos de caja</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ sumarizarAbonosYsalir() }
        } >
    <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
    </Col>
  </Row>             

  {/* Datos del documento generador: numero, fecha, total */}
  <div id="divBloque1" name="divBloque1"  style={{width:'98%',marginLeft:'1%',marginRight:'1%',marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>    
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0',}}>
    <Col xs="6" style={{display:"flex",}}>
      <Label style={{width:"30%" }} >Documento</Label>
      <Input name="txtTipoDoc" id="txtTipoDoc" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
    </Col>
    <Col xs="6" style={{display:"flex",}}>
      <Label style={{width:"30%" }}>Número</Label>
      <Input id="txtNumDoc" name="txtNumDoc" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
    </Col>  
  </Row>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0',}}>
    <Col xs="6" style={{display:"flex",}}>
      <Label style={{width:"30%" }} >Fecha</Label>
      <Input name="txtFecha" id="txtFecha" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
    </Col>
    <Col xs="6" style={{display:"flex",}}>
      <Label style={{width:"30%" }} >Total</Label>
      <Input id="txtMonto" name="txtMonto" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
    </Col>  
  </Row>
  </div> {/* divBloque1 */}

  {/* lista de los recibos de pago */}
  {ParteGrid()}

  {/* ************************* MODAL PARA ENVIAR CORREO *************** */}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalEnviarCorreo } >
      <FormularioCorreo 
        cerrarModalCorreo={async(accion,miAsunto,miMensaje)=>{
          set_modalEnviarCorreo(false)
          //accion puede ser: close/send
          if (accion=='close') return  
          if (accion=='send') {
            let correoEnviadoCorrectamenteSN=await enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal(props.clienteNombre,props.clienteEmail,'S','REC_' + rowRegistroClon.Estab + "-" + rowRegistroClon.Punto + "-" + rowRegistroClon.NumRecibo + ".pdf",valueDC,miAsunto,miMensaje)
            if (correoEnviadoCorrectamenteSN=="S") colocarSIenExC() //tildo en el grid y en la base de datos. Pero al actualizar en la BDD no me interesa esperar ni usar swal   
          }
        }}
        destinoNombre={props.clienteNombre}
        destinoEmail={props.clienteEmail}
        asuntoDefault={"RECIBO DE PAGO"}
        mensajeDefault={"Atención: \n" + props.clienteNombre}
      />
    </Modal>

  {/************ MODAL PARA PEDIR LA CLAVE ESPECIAL y luego eliminar *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalPedirClaveEspecial } >
    <PedirClaveEspecial
      cerrarModal={(claveCorrecta)=> {
        set_modalPedirClaveEspecial(false)
        if (claveCorrecta) eliminarReciboEnBDD()
      }}
      claveEnBDD={valueDC.licencia.ClaveOperacionesEspeciales}      
      subtitulo={"eliminar el recibo: " + rowRegistroClon?.Recibo}
    />
  </Modal> 

  {/* *********** MODAL PARA VER EL PDF DEL RECIBO  ************ */}
  <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalVerRecibo } >
    <VerPdfOk
      documentoTitulo={"RECIBO DE INGRESO"}
      documentoArchivo={"REC_" + rowRegistroClon?.Estab + "-" + rowRegistroClon?.Punto + "-" + rowRegistroClon?.NumRecibo }
      corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"N"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ ()=>{
        set_modalVerRecibo(false)
      }}   
    />
  </Modal> 

  </div> // divMaster
)
} 

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} //de RecibosIngresoDeUnDocumento

export default RecibosIngresoDeUnDocumento
