//Solo me permite visualizar un PDF. 

import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import {buscarCarpeta_docs_temporal_o_sri} from './lib_basica'
//del context
import {AppContextConsumer} from './ContextBase'
//variables normales
let valueDC=null //Aqui hago una copia de los valores del context

const VerPdfOk=(props)=>{
  //variable de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

  const cargarPdf=()=>{
    //asi funciona perfecto
    return new Promise( listo =>{
      let miArchivoLoco= buscarCarpeta_docs_temporal_o_sri(props.tipoSriSN) + props.documentoArchivo + ".pdf"
      document.querySelector('#miFrame').setAttribute('src',miArchivoLoco)
      listo()
    })
  }
    
//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //limpio cualquier dato pdf viejo
  document.querySelector('#miFrame').setAttribute('src',null)
  cargarPdf()  

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //nada por aca
  }  
  },[]
)

const ComponentePrincipalBody=()=>{
//OJO: cuando quiero que se muestre como MODAL tiene un vh por ejemplo de 90 y 85
//y cuando sea full pantalla, entonces el vh deberia ser 100 y 98
//a ese parametro lo llamo: estiloPantallaPG (osea Pequeno o Grande)
let miTituloDef=props.documentoTitulo + ( (props.corchetesSN=="S")  ?  " ["+props.documentoArchivo +"]" : "" )     
let heightDivMaster='100vh'
let heightDivPdf='100vh'
//pregunto si desea pantalla pequena
if (props.estiloPantallaPG=="P"){
  heightDivMaster='90vh'
  heightDivPdf='87vh'
}

return (                
<div id="divMaster" name="divMaster" style={{background:'hotPink',height:heightDivMaster}} > 
  {/* Titulo + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontSize:'18px', fontWeight:'bold', color:'white'}}>{ miTituloDef } </Label>  
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ props.activarMenu()  }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 

  <div id="divPdf" name="divPdf" style={{width:'100%',background: 'hotPink', height:heightDivPdf,} } >
    {/*  justifyContent:'center', alignItems:'center' */}
    <iframe id="miFrame" name="miFrame" style={{width:'100%', height:heightDivPdf,}}>
    </iframe>
  </div>  

</div> // divMaster
)} 

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //de VerPdfOk

export default VerPdfOk