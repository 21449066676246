import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//para comparar la clave escrita vs la encriptada en la bdd
import bcrypt from 'bcryptjs'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,sleepPepe } from './lib_basica'
 
const PedirClaveEspecial=(props)=> {
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const validarYsalir=async()=>{
  let claveEscrita=document.getElementById('txtClave').value.trim()

  if (claveEscrita.length  == 0) {
    await mostrarSwalBotonAceptar("error","ATENCION","la clave no puede estar vacía")
    return
  }
 
  let estatusClave=bcrypt.compareSync(claveEscrita, props.claveEnBDD)  
  if (!estatusClave) {
    await mostrarSwalBotonAceptar("error","ATENCION","Clave inválida")
    return
  }

  //OJO: cierro el modal e indico que SI escribio correctamente la clave
  props.cerrarModal(true)
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  document.getElementById("txtClave").focus()  
  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //Por ahora nada
  }  
  },[]
)
  
const ComponentePrincipal=()=>{
return (
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
    {/* Boton Cerrar*/}
    <Row style={{margin:'0',}}>
      <Col xs="10" >
        <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{'Indíque la clave especial, para'}</Label>
        <Label style={{fontWeight: 'bold'}}>{props.subtitulo}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ props.cerrarModal(false)  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
      </Col>
    </Row> 
      {/* cuerpo del formulario, cuadrito para meter la clave*/}
    <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink', }}>
      <Row style={{margin:'0'}}>
        <Col xs="7" >
            <Input type="password" style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px',marginTop:'20px' }} name="txtClave" id="txtClave"      
            onKeyDown = {(e) => { 
              if ( e.key =="Enter" )         
                { 
                  //e.preventDefault()   //obligatorio ponerlo
                  validarYsalir()                                 
                }
            }}/>      
        </Col>
        <Col xs="5" style={{textAlign:'center',}}>    
          <Button style={{width:'120px', height:'120px',fontSize:'60px',}} id="btnEnter" name="btnEnter" color="success" 
            onClick= {()=> {
              validarYsalir() 
            }}>
            <FontAwesomeIcon color="purple" icon={faCheck} /> 
          </Button>
        </Col>         
      </Row>  
  </div> {/* divBloque1 */}
  </div> // divMaster
)}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del PedirClaveEspecial

export default PedirClaveEspecial
