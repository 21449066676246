//Solo me permite ver los campos con la consulta/autorizacion del sri
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
import { detectarTipoDeDocumentoSri } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar} from './lib_basica'
//del context
import {AppContextConsumer} from './ContextBase'
//variables normales
let valueDC=null //Aqui hago una copia de los valores del context
 
const MostrarConsultaAutorizacionSri=(props)=>{
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const mostrarDatos=()=>{    
  document.getElementById("txtClave").value=props.pepeJson.ClaveAccesoConsultada
  //son los 2 digitos despues de la fecha. caracter 9 y 10
  let miCod=props.pepeJson.ClaveAccesoConsultada.substring(8,10);
  document.getElementById("txtTipoDoc").value=detectarTipoDeDocumentoSri(miCod)
  document.getElementById("txtEstado").value=props.pepeJson.Estado
  document.getElementById("txtFechaAutori").value=props.pepeJson.FechaAutori
  document.getElementById("txtAmbiente").value=props.pepeJson.Ambiente
  let miMensaje = (props.pepeJson.Tipo==null ? "" : props.pepeJson.Tipo )+" "+(props.pepeJson.Identificador==null ? "" : props.pepeJson.Identificador)+" "+(props.pepeJson.Mensaje==null ? "" : props.pepeJson.Mensaje )
  miMensaje=miMensaje.trim()
  document.getElementById("txtMensaje").value=miMensaje
  document.getElementById("txtInformacionAdicional").value=props.pepeJson.InformacionAdicional
  document.getElementById("txtComprobante").value=props.pepeJson.Comprobante
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  mostrarDatos()

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //por ahora nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{       
return (               
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

    {/* Titulo + Boton Cerrar*/}
    <Row style={{margin:'0',}}>
      <Col xs="10" >
        <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"CONSULTA DE COMPROBANTE"}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ props.cerrarModal()  }} >
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
    </Row> 

    {/* Datos de la autorizacion */}
    <div id="divDatos" name="divDatos" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'0%',background:'purple', color:'pink', }}>
      <Row style={{marginBottom:"4%",marginLeft:'0',marginRight:'0',paddingTop:'3%'}}>
        <Col xs="12">
          <Input disabled name="txtClave" id="txtClave" style={{paddingLeft:'0px',textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>
      </Row>  
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Documento</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtTipoDoc" id="txtTipoDoc" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Estado</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtEstado" id="txtEstado" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>F. Autoriz.</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtFechaAutori" id="txtFechaAutori" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Ambiente</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtAmbiente" id="txtAmbiente" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Mensaje</Label>
        </Col>
        <Col xs="8">
          <Input disabled name="txtMensaje" id="txtMensaje" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', }}/>            
        </Col>    
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Col xs="4">
          <Label>Info Adicional</Label>
        </Col>
        <Col xs="8">
          <textarea disabled name="txtInformacionAdicional" id="txtInformacionAdicional" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'90px', }}/>            
        </Col>    
      </Row>  
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
        <Label style={{color:'gold'}}>Comprobante</Label>
        <Col xs="12">
          <textarea disabled name="txtComprobante" id="txtComprobante" style={{textAlign:'left',width:'100%',height:'30vh',fontSize:'14px' }}/>            
        </Col>
      </Row>  

    </div> {/* divDatos  */}
  </div> // divMaster
) 
} 

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

}//del MostrarConsultaAutorizacionSri

export default MostrarConsultaAutorizacionSri
