//Solo me permite llenar el formulario y devuelve el ASUNTO y MENSAJE al llamador
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet} from './lib_basica'
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let miAsuntoDevolver=null
let miMensajeDevolver=null
//context
let valueDC=null  

const FormularioCorreo=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const validarCasillasLlenas=()=>{
 //Valido que este lleno el formulario
 miAsuntoDevolver=document.getElementById("txtAsunto").value.trim()
 miMensajeDevolver=document.getElementById("txtMensaje").value.trim()

 if (miAsuntoDevolver.length==0){
  mostrarSwalBotonAceptar("warning","NO SE PUEDE ENVIAR","El asunto NO puede estar vacío")
  return false
 }
 if (miMensajeDevolver.length==0){
  mostrarSwalBotonAceptar("warning","NO SE PUEDE ENVIAR","El mensaje NO puede estar vacío")
  return false
 }
  return true
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //coloco el Asunto y Mensaje que viene desde el llamador
  document.getElementById("txtAsunto").value=props.asuntoDefault
  document.getElementById("txtMensaje").value=props.mensajeDefault
  document.getElementById("txtAsunto").focus()  
},[]
)

const ComponentePrincipalBody=()=>{         
return(               
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}> 
    {/* Titulo + Boton Cerrar*/}
    <Row style={{margin:'0',}}>
      <Col xs="10" style={{display:'grid'}}>
        <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"PARA: " + props.destinoNombre}</Label>
        <Label style={{marginLeft:'0%',}}>{props.destinoEmail}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ props.cerrarModalCorreo('close')  }} >
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
    </Row> 

    {/* Fila para poner el asunto y Mensaje */}
    <div style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink'}}>
      <Label for="txtAsunto">Asunto</Label>
      <Row style={{margin:'0',}}>
        <Col xs="12">
          <Input name="txtAsunto" id="txtAsunto" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px' }}/>            
        </Col>
      </Row>  
      <Label for="txtMensaje" style={{marginTop:"15px"}}>Mensaje</Label>
      <Row style={{margin:'0',}}>
        <Col xs="12">
          <textarea name="txtMensaje" id="txtMensaje" style={{textAlign:'left',width:'100%',height:'45vh', fontSize:'16px' }}/>            
        </Col>
      </Row>  
      {/* Boton Enviar(send) */}
      <Row style={{margin:'0',}}>
        <Col xs="6" style={{textAlign:'left',marginTop:'40px', color:'silver',}}>           
        </Col>   
        <Col xs="6" style={{textAlign:'right',marginTop:'20px'}}>    
          <Button size="lg" id="btnEnviar" name="btnEnviar" color="success" solid="true" 
            onClick={async()=>{
              if (!validarCasillasLlenas()) return
              props.cerrarModalCorreo('send',miAsuntoDevolver,miMensajeDevolver)  //le envio al llamador 3 cosas        
              }}>ENVIAR
          </Button>
        </Col>         
      </Row>    
    </div>  
  </div> //divMaster
)}  

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} // de FormularioCorreo

export default FormularioCorreo
